#question-container {
  .question-title {
    display: flex;
    align-items: baseline;
    color: rgb(70 70 70);
    min-height: 130px;
    min-width: 400px;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 auto;
      margin-right: 1rem;
      width: 3rem;
      height: 3rem;
      padding: 1rem;
      border-radius: 50%;
      background-color: #00afb9;
      color: white;
      font-size: 36px;
      font-weight: bold;
    }
  }

  .options-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    @media (max-width: 768px) {
      flex-direction: column;

      button {
        margin-top: 10px;
      }
    }
  }
}

#question-fade-container {
  transition: opacity 0.5s;

  &.fade-out {
    opacity: 0;
  }
}
