.quiz-button {
  align-items: center;
  background-color: #fdfcdc;
  border: 1px solid #fed9b7;
  border-radius: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: rgba(54, 54, 54, 0.85);
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui, -apple-system, system-ui, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
}

.quiz-button:hover,
.quiz-button:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.quiz-button:hover {
  transform: translateY(-1px);
}

.quiz-button:active {
  background-color: #fdfcdc;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}

.quiz-button-selected {
  background-color: #f07167;
  color: #fff;
  border: none;
}

.quiz-button-flash {
  animation: glowing 500ms infinite;
}

@keyframes glowing {
  0% {
    background-color: #fdfcdc;
  }

  100% {
    background-color: #f07167;
  }
}
