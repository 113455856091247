#questions-main {
  margin-top: 50px;
  box-shadow: 0.1rem 0.1rem 1.5rem rgba(0, 0, 0, 0.3);
  border-radius: 0.25rem;
  min-width: 600px;

  @media (max-width: 768px) {
    min-width: 100%;
  }

  .questions-progress-bar {
    height: 15px;
    width: calc(var(--current) / var(--total) * 100%);
    background-color: #fed9b7;
  }

  .questions-content {
    padding: 2rem 2rem 1rem;
  }

  .questions-nav-container {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
  }

  .questions-nav-button {
    border: none;
    background: none;
    display: flex;
    transition: all 250ms;
    opacity: 0.6;

    div {
      font-size: 18px;
      line-height: 30px;
      padding: 0px 10px;
    }

    img {
      width: 30px;
      height: 30px;
    }

    .nav-back-arrow {
      transform: rotate(-90deg);
    }

    .nav-next-arrow {
      transform: rotate(90deg);
    }

    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }

  .nav-button-hidden {
    visibility: hidden;
  }
}
