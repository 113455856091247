#results-main {
  margin-top: 50px;
  box-shadow: 0.1rem 0.1rem 1.5rem rgba(0, 0, 0, 0.3);
  border-radius: 0.25rem;
  text-align: center;

  h1 {
    text-align: center;
    padding-top: 20px;
  }

  .results-content {
    padding: 0rem 2rem 1rem;
  }
}

#results-promo-box {
  background-color: #d5a88c;
  width: 100vw;
  padding: 20px 0;
  margin-top: 40px;

  .promo-box-top {
    display: flex;
    flex-direction: row;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .promo-box-content {
    max-width: 700px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    h3 {
      text-align: center;
      font-family: 'Fraunces', serif;
      font-size: 32px;
      margin-top: 0;
    }

    img {
      box-shadow: 0 0 5px -1px black, inset -1px 1px 2px rgba(255, 255, 255, 0.5);
      height: 400px;

      @media (max-width: 768px) {
        width: 85vw;
        height: auto;
      }
    }

    .promo-box-text {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      font-size: 20px;
      font-family: 'Poppins', Sans-serif;
      background-color: #fff;
      border-radius: 15px;
      padding: 0px 20px;
      text-align: center;
      justify-content: center;

      @media (max-width: 768px) {
        margin: 40px 20px 0 20px;
      }
    }

    .promo-box-link {
      background-color: #f75600;
      text-decoration: none;
      font-size: 22px;
      padding: 10px 0;
      margin-top: 30px;
      text-align: center;
      border-radius: 70px;
      color: #fff7f7;
      font-weight: 600;
      transition: transform 0.5s;
    }

    .promo-box-link:hover {
      transform: scale(1.1);
    }
  }
}
